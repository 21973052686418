// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
:host {
  /* OR within a component's stylesheet using ::ng-deep */
}
:host .filter-label-text {
  font-size: 22px;
  font-weight: bold;
}
:host .filter-projects-title {
  color: #ffffff;
  font-size: 23px;
}
:host .imitate-angular-material {
  border-bottom: 1px solid #76bc21;
  margin-top: -7px;
}
:host .status-filter-label {
  font-size: 16px;
  font-weight: bold;
}
:host .cancel-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: #231f20;
  opacity: 0.4;
  vertical-align: -4px;
}
:host ::ng-deep.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
:host .ng-select::ng-deep .ng-select-container {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
:host ::ng-deep.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
  min-height: 33px;
}
:host ::ng-deep.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
  border-radius: 20px;
  color: #ffffff;
  font-size: 14px;
  margin-right: 13px;
  padding: 5px 8px 5px 8px;
}
:host ::ng-deep.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  border: none;
}
:host ::ng-deep.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: transparent;
}
:host ::ng-deep.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value:hover {
  background-color: rgba(35, 31, 32, 0.31);
}
:host ::ng-deep .mat-input-element::placeholder {
  color: white;
}
:host .mat-mdc-form-field::ng-deep,
:host .mat-mdc-form-field-flex::ng-deep {
  color: #ffffff;
}
:host .mat-mdc-form-field::ng-deep .mat-mdc-input-element,
:host .mat-mdc-form-field-flex::ng-deep .mat-mdc-input-element {
  color: #ffffff;
}
:host .mat-mdc-form-field::ng-deep.mat-date-range-input,
:host .mat-mdc-form-field-flex::ng-deep.mat-date-range-input {
  margin-top: 9px;
  margin-bottom: 9px;
}
:host .mat-mdc-form-field::ng-deep.mat-date-range-input .mat-input-element,
:host .mat-mdc-form-field-flex::ng-deep.mat-date-range-input .mat-input-element {
  margin-top: 0;
  margin-bottom: 0;
}
:host .mat-mdc-form-field::ng-deep .mat-datepicker-toggle,
:host .mat-mdc-form-field::ng-deep .mat-date-range-input-separator,
:host .mat-mdc-form-field-flex::ng-deep .mat-datepicker-toggle,
:host .mat-mdc-form-field-flex::ng-deep .mat-date-range-input-separator {
  color: #ffffff;
}
:host .mat-mdc-form-field::ng-deep .mat-datepicker-toggle button:focus,
:host .mat-mdc-form-field::ng-deep .mat-date-range-input-separator button:focus,
:host .mat-mdc-form-field-flex::ng-deep .mat-datepicker-toggle button:focus,
:host .mat-mdc-form-field-flex::ng-deep .mat-date-range-input-separator button:focus {
  outline: none;
}

.amount-column {
  width: 65%;
  margin-right: 50px;
}

.number-column {
  width: 25%;
}

.spacing-column {
  width: 10%;
}

.imitate-angular-material {
  border-bottom: 1px solid #76bc21;
}

::ng-deep.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
::ng-deep.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #76bc21;
}

::ng-deep.mat-mdc-form-field-focus-overlay {
  background: transparent;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
