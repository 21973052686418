// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `dialog,
dialog::backdrop {
	background: rgba(0, 0, 0, 0.4);
}

dialog[open] {
	animation: dialog-in 125ms ease-out forwards;
}

dialog[open]::backdrop {
	animation: backdrop-in 125ms ease-out forwards;
}

dialog.closing[open] {
	animation: dialog-out 125ms ease-in forwards;
}

dialog.closing[open]::backdrop {
	animation: backdrop-out 125ms ease-in forwards;
}

@keyframes backdrop-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes backdrop-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes dialog-in {
	0% {
		opacity: 0;
		transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes dialog-out {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0.95);
	}
}

@media (prefers-reduced-motion) {
	.drawer {
		dialog[open] {
			animation: none;
		}
		dialog[open]::backdrop {
			animation: none;
		}

		dialog.closing[open] {
			animation: none;
		}
		dialog.closing[open]::backdrop {
			animation: none;
		}
	}
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
