// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.custom-pagination .page-number {
  display: inline-block;
  background: #ffffff;
  border: 2px solid #eaeaea;
  margin: 0 4px;
  border-radius: 25px;
}

.page-number.current {
  background: #ffffff !important;
  border: 2px solid #414448 !important;
}

.page-number span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}

.pagination-previous,
.pagination-next {
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
}

.pagination-previous span,
.pagination-next span {
  padding: 8px 14px;
  background-color: #414448;
  border: 2px solid #414448;
  color: #ffffff;
  border-radius: 25px;
  margin-left: 5px;
  margin-right: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
