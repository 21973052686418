// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.bg-percentage-green {
  background-color: #15b422;
}

.bg-percentage-light-green {
  background-color: #a3d000;
}

.bg-percentage-yellow {
  background-color: #fcb000;
}

.bg-percentage-red {
  background-color: #fcb000;
}

.progress-bar {
  height: 10px;
  border-radius: 7px;
}

.progress-bar-border {
  border-radius: 12px;
  border: 2px solid #aab2c0;
}

.title {
  min-width: 130px;
}

.coloured-dot,
.status-coloured-dot {
  height: 10px;
  width: 10px;
  margin-top: 2px;
  min-width: 10px;
}

::ng-deep .ng-select .ng-select-container {
  border: 1px solid lightgrey;
}

.project-stats {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 2px;
}
.project-stats .stat {
  background-color: #414448;
  overflow: hidden;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.project-stats .stat.left {
  border-radius: 0.5rem 0 0 0.5rem;
}
.project-stats .stat.right {
  border-radius: 0 0.5rem 0.5rem 0;
}

.comment-container .comment-detail {
  overflow-y: scroll;
  background-color: white;
  border: 1px solid rgba(211, 211, 211, 0.75);
  border-radius: 0.5rem;
  padding: 1rem 1rem 0 1rem;
  width: 100%;
  height: 100%;
  max-height: 400px;
  min-height: 200px;
}

.comment {
  padding: 1rem;
}
.comment p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.comment:hover {
  cursor: pointer;
  background-color: rgba(211, 211, 211, 0.3);
  border-radius: 0.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
