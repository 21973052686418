// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.header {
  height: 42px;
  margin-left: 67px;
  pointer-events: none;
  z-index: 999;
}

.page-content {
  padding: 3rem 2rem;
  min-height: inherit;
}

.side-nav-menu {
  width: 67px;
}

.coms-spacer {
  top: 60px !important;
}

.main-coms-spacer {
  margin-top: calc(60px + 8px) !important;
}

::ng-deep .cdk-overlay-container {
  z-index: 2000;
}
::ng-deep .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0 !important;
}
::ng-deep .mat-icon {
  height: auto !important;
}
::ng-deep .input-field.bg-none .mat-mdc-text-field-wrapper {
  background: transparent !important;
}
::ng-deep .mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background: transparent !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
