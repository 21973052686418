// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin-top-45px {
  margin-top: 45px;
}

.position-left-0 {
  left: 0;
}

.position-right-0 {
  right: 0;
}

.corner-badge {
  width: 22px;
  height: 22px;
  background-color: #99cb3c;
  margin-left: -22px;
}
.corner-badge .number {
  color: white;
}

.object-fit-contain {
  object-fit: contain;
  object-position: left;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
