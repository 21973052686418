export const whiteLabelConfig = {
	app: {
		title: 'Paint Projex',
		logoSideNav: '../assets/img/LogoSideNav.png',
		watermarkImg: '../assets/img/Measuring_Tool_Watermark.png',
		logoWhite: '../assets/img/UG_Logo_Primary_White.svg',
	},
	dashboard: {
		dashboardStrikeRate: {
			showBrushIcon: false,
		},
	},
	login: {
		showBrushIcon: false,
		showLogo: true,
		logoPrimaryColor: '../assets/img/Logo_Primary_COL.svg',
	},
};
