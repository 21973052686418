// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.drop-list .item {
  margin-top: 2.5px;
  margin-bottom: 2.5px;
}
.drop-list .item:first-of-type {
  margin-top: 5px;
}
.drop-list .item:last-of-type {
  margin-bottom: 5px;
}

.active .item {
  cursor: grab;
  padding-top: 6px;
}
.active .item.selected {
  background-color: #76bc21;
  opacity: 0.6;
}

.item-dragging .item.selected:not(.cdk-drag-placeholder) {
  opacity: 0.3;
}

.select-item-drag-preview {
  background-color: rgba(204, 0, 102, 0);
  font-weight: bold;
  display: inline-block;
  line-height: 30px;
  text-align: center;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.item-dragging.multi-item-dragging *:last-of-type {
  padding-bottom: 10px;
}
.item-dragging.multi-item-dragging *:first-of-type {
  padding-top: 10px;
}

.hide .item.selected.cdk-drag-placeholder {
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
