// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
:host {
  margin-left: auto;
  pointer-events: all;
}

.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.custom-dropdown-menu {
  border-radius: 8px;
  margin-top: 5px;
  padding-top: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 250px;
  border: 1px solid rgba(211, 211, 211, 0.75);
  box-shadow: 0 0.5rem 1rem rgba(35, 31, 32, 0.15) !important;
}

.custom-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black !important;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.icon {
  color: #d3d3d3;
}

.dropdown-toggle {
  box-shadow: none;
}
.dropdown-toggle::after {
  display: none;
}

.unread-alerts {
  border-radius: 10px;
  font-weight: 500;
  color: white;
  background-color: #dc3545;
}

.cleared-alerts {
  border-radius: 10px;
  color: white;
  background-color: #76bc21;
}

.greeting-message {
  padding: 10px 10px 0 10px;
  font-size: 15px;
  font-weight: 600;
}

.menu-badge {
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
