import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
	providedIn: 'root'
})

export class DateFormattingService {
	constructor(private translocoService: TranslocoService) {
	}

	public formatDate(date: Date): Date {
		const currentLang = this.translocoService.getActiveLang();
		let formattedDate;
		if (currentLang === 'en-us') {
			let formattedDateString = format(date, 'MM/dd/yyyy');
			formattedDate = new Date(formattedDateString);
		} else {
			let formattedDateString = format(date, 'dd/MM/yyyy');
			formattedDate = new Date(formattedDateString);
		}
		return formattedDate;
	}

	public formatDateString(date: Date): string {
		const currentLang = this.translocoService.getActiveLang();
		let formattedDate;
		if (currentLang === 'en-us') {
			formattedDate = format(date, 'MM/dd/yyyy');
		} else {
			formattedDate = format(date, 'dd/MM/yyyy');
		}
		return formattedDate.toString();
	}
}