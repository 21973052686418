// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-dropdown-panel {
	position: absolute;
}

.ng-select {
	position: initial;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.centerLabel {
	text-align: center;
}

label {
	font-weight: bold;
}

.mergeTable td {
	border-bottom: 1px solid rgba(150, 150, 150, 0.3);
}

.mergePicture {
	height: 131px;
	width: 131px;
	margin-left: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
}

@media (min-width: 350px) and (max-width: 575px) {
	.client-merge-dialog {
		width: 300px;
	}
}
@media (min-width: 576px) {
	.client-merge-dialog {
		width: 500px;
	}
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
