// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
section {
  background-color: #ffffff;
}
section .expanding-menu {
  width: 185px;
}

.primary-color {
  color: #76bc21;
}

.minimizePanel {
  width: 2rem;
}

.project-title {
  padding: 2.4rem 0 0.8rem 0;
}

.my-scrollable-list {
  flex: 1 1;
  overflow-y: scroll;
}

.selected:not(.parent-list-option) {
  color: #76bc21;
  background-color: #cee2b6;
}

.bottom-button {
  padding: 1rem 4rem 1rem 4rem;
}

.span {
  width: 1rem;
}

.hover-on-item :hover {
  color: #76bc21;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.my-scrollable-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.my-scrollable-list {
  scrollbar-width: none; /* Firefox */
}

.border-style {
  border-right: 1px solid #76bc21;
}

.disabled {
  color: #caced5 !important;
  pointer-events: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
