// Framework imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ItcBusyModule } from '../../itc-busy/component/itc-busy.module';
import { ModalDirectiveComponent } from '../modal.component';
import { TranslocoPipe } from '@jsverse/transloco';

@NgModule({
	declarations: [ModalDirectiveComponent],
	exports: [ModalDirectiveComponent],
	imports: [CommonModule, ModalModule, MatSlideToggleModule, ItcBusyModule, TranslocoPipe],
	providers: [],
})
export class ItcModalDirectiveModule {}
