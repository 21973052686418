// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  pointer-events: all;
}
:host .search-bar {
  width: 12rem;
  position: relative;
  top: -0.2rem;
}
:host .search-bar .search-input {
  height: 22px;
  border-radius: 5px;
  display: block;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #ffffff;
  background-clip: padding-box;
  margin-bottom: 10px;
}
:host .search-bar .search-input:focus {
  border-color: var(--primary-color) !important;
  border-radius: 0.375rem !important;
  border-width: 3px !important;
}
:host .search-bar ::ng-deep .icon-style {
  font-size: 1.2rem;
  position: relative;
  top: -0.2rem;
}
:host .search-bar ::ng-deep typeahead-container {
  max-height: 250px;
  max-width: 250px;
}
:host .search-bar ::ng-deep typeahead-container .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
