// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itc-busy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2002;
  background: rgba(255, 255, 255, 0.7803921569);
  opacity: 1;
  text-align: center;
}

.itc-busy-default-sign {
  position: relative;
  display: inline-block;
  z-index: 2003;
  padding: 12px 14px;
  border: 1px solid #d8d8d8;
  margin-top: 2px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background: #f8f8f8;
  color: #333;
  opacity: 1;
}

.itc-busy-default-text {
  display: inline-block;
  margin-left: 6px;
  max-width: 400px;
  font-size: 14px;
  text-align: left;
}

/* Start animated loading icon */
.itc-busy-default-spinner {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

.itc-busy-default-spinner div {
  position: absolute;
  left: 44.5%;
  top: 37%;
  width: 10%;
  height: 26%;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: busy-spinner-anim 1s linear infinite;
}

.itc-busy-default-spinner .bar1 {
  transform: rotate(0deg) translate(0, -142%);
  animation-delay: -1s;
}

.itc-busy-default-spinner .bar2 {
  transform: rotate(30deg) translate(0, -142%);
  animation-delay: -0.91666667s;
}

.itc-busy-default-spinner .bar3 {
  transform: rotate(60deg) translate(0, -142%);
  animation-delay: -0.83333333s;
}

.itc-busy-default-spinner .bar4 {
  transform: rotate(90deg) translate(0, -142%);
  animation-delay: -0.75s;
}

.itc-busy-default-spinner .bar5 {
  transform: rotate(120deg) translate(0, -142%);
  animation-delay: -0.66666667s;
}

.itc-busy-default-spinner .bar6 {
  transform: rotate(150deg) translate(0, -142%);
  animation-delay: -0.58333333s;
}

.itc-busy-default-spinner .bar7 {
  transform: rotate(180deg) translate(0, -142%);
  animation-delay: -0.5s;
}

.itc-busy-default-spinner .bar8 {
  transform: rotate(210deg) translate(0, -142%);
  animation-delay: -0.41666667s;
}

.itc-busy-default-spinner .bar9 {
  transform: rotate(240deg) translate(0, -142%);
  animation-delay: -0.33333333s;
}

.itc-busy-default-spinner .bar10 {
  transform: rotate(270deg) translate(0, -142%);
  animation-delay: -0.25s;
}

.itc-busy-default-spinner .bar11 {
  transform: rotate(300deg) translate(0, -142%);
  animation-delay: -0.16666667s;
}

.itc-busy-default-spinner .bar12 {
  transform: rotate(330deg) translate(0, -142%);
  animation-delay: -0.08333333s;
}

@keyframes busy-spinner-anim {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
