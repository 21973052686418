// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.current-page-item {
  color: #76bc21;
  font-weight: bold;
}

.item-row {
  border-bottom: 1px solid #d3d3d3;
}

.options-list-item {
  text-decoration: none;
  color: #000000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
