// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.contact-person-table-headers * {
  font-size: 14px;
}

.main-container {
  margin-bottom: 108px;
}

.footer {
  height: 108px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #efefef;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
