// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-radius-25px {
  border-radius: 25px;
}

.project-status-container {
  margin-bottom: 2rem;
}

.progress-circle {
  width: 13px;
  height: 13px;
}

.status-line {
  border-top: 3px solid gray;
  margin-top: -20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
