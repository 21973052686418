// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.button-container {
  position: relative;
  box-shadow: none;
  outline: none;
}

.add-icon-suffix {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  background-color: #ffffff;
  border-radius: 50%;
}

.icon-label {
  text-decoration: underline;
}

.icon {
  color: #76bc21;
}
.icon ::ng-deep .drop-svg {
  fill: #76bc21;
  stroke: #76bc21;
}

button.has-border {
  padding: 0.375rem 0.75rem !important;
}
button.has-border .add-icon-suffix {
  top: 20px;
  left: 24px;
}
button.has-border:hover i {
  color: white;
  transition: 0.1s;
}
button.has-border:hover ::ng-deep .drop-svg {
  fill: white;
  stroke: white;
  transition: 0.1s;
}

.extra-width {
  width: 14px;
  height: 26px;
}

fa-icon {
  font-size: 20px;
  color: #76bc21;
}
fa-icon.faFileAlt {
  font-size: 25px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
