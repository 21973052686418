import { UntypedFormGroup } from '@angular/forms';

import { Company, Contact, State, Comment } from '../interfaces';

export class CompanyModel
	// extends BaseModel
	implements Company
{
	// Properties
	public id: string;
	public abn: string;
	public addressLine1: string;
	public addressLine2: string;
	public addressLine3: string;
	public city: string;
	public contacts: Contact[];
	public displayPictureKey: string;
	public displayPictureUrl: string;
	public email: string;
	public fax: string;
	public isActive: boolean;
	public isClient: boolean;
	public isProvider: boolean;
	public name: string;
	public parentClient: Company;
	public phone: string;
	public imageUrl: string;
	public postalCode: string;
	public industryLicenseNumber: string;
	public state: State;
	public website: string;
	public isParent: boolean;
	public providerChildren: Company[];
	public comments: Comment[];
	public xeroContactId?: string;
	// Display values
	public displayedContact: Contact;

	constructor(model?: Partial<CompanyModel>) {
		if (model) {
			Object.assign(this, model);
		}

		if (this.contacts && this.contacts.length > 0) {
			this.displayedContact = this.contacts[0];
		}
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}
}
