// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.avatar {
  width: 42px;
  height: 42px;
  margin-left: 14px;
}

.unread-alerts {
  border-radius: 10px;
  font-weight: 500;
  color: white;
  background-color: #dc3545;
}

.cleared-alerts {
  border-radius: 10px;
  color: white;
  background-color: #76bc21;
}

.custom-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
}

.custom-dropdown-menu {
  border-radius: 8px;
  margin-top: 5px;
  padding-top: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 250px;
  border: 1px solid rgba(211, 211, 211, 0.75);
  box-shadow: 0 0.5rem 1rem rgba(35, 31, 32, 0.15) !important;
}

.greeting-message {
  padding: 10px 10px 0 10px;
  font-size: 14px;
  font-weight: 700;
}

.comment-icon {
  position: relative;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.25rem;
  margin-left: 0.5rem;
  cursor: pointer;
}
.comment-icon .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #76bc21;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
}
.comment-icon:hover {
  background-color: rgba(211, 211, 211, 0.4);
}
.comment-icon:hover svg {
  color: #414448;
}
.comment-icon svg {
  color: gray;
}
.comment-icon .unread-alerts {
  border-radius: 10px;
  font-weight: 500;
  color: white;
  background-color: #dc3545;
}
.comment-icon .cleared-alerts {
  border-radius: 10px;
  color: white;
  background-color: #76bc21;
}

.menu-badge {
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
