// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.column-count-auto {
  column-count: auto !important;
}

@media (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem;
  }
}
.dark-background {
  background-color: #414448;
  margin-bottom: -110px;
  margin-top: -150px;
  padding-bottom: 110px;
  padding-top: 150px;
}

.dark-background-overlay {
  z-index: 1;
}

.project-filter {
  margin-bottom: 20px;
  margin-top: 60px;
}

.main-header-menu {
  position: fixed;
  top: 45px;
  left: 67px;
  right: 0;
  padding-left: 15px;
  background-color: #efefef;
  z-index: 20;
}
.main-header-menu.coms-spacer {
  top: 39px;
}

@media (max-width: 1000px) {
  .main-header-menu {
    width: -moz-fit-content;
    width: fit-content;
  }
}
.sub-header-menu {
  position: fixed;
  top: 0;
  left: 68px;
  width: calc(100% - 66px);
  background-color: #efefef;
  height: 2.5rem;
  z-index: 10;
  padding-left: 15px;
}
.sub-header-menu.coms-spacer {
  top: 60px;
}

.heading {
  margin-top: calc(60px - 20px);
}

.search-bar-container {
  position: relative;
  top: 0.4rem;
}

.header-menu {
  position: relative;
  top: 0.2rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
