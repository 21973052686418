// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.nav-warning {
  padding: 54px;
  width: 392px;
}
.nav-warning h3 {
  color: #231f20 !important;
  letter-spacing: 1.1px;
}
.nav-warning .btn {
  width: 15rem;
  color: #231f20;
  padding-top: 10px;
  padding-bottom: 10px;
}
.nav-warning .btn:hover {
  color: #ffffff;
}

.top-button {
  margin-top: 54px;
}

.bottom-button {
  margin-top: 22px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
