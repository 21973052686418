// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.right-border::before {
  content: "";
  position: absolute;
  background-color: #eee;
  width: 80%;
  height: 1px;
  bottom: -17px;
  left: 5%;
}

.right-border::after {
  content: "";
  position: absolute;
  background-color: #76bc21;
  width: 1px;
  height: 65%;
  top: 15%;
  right: 0;
}

a {
  text-decoration: none;
}
a:hover {
  color: white;
}

.custom-margin {
  margin-top: 5px;
}

.help-card {
  width: 250px;
  height: 110px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
