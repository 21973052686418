// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: none !important;
  border-color: #76bc21 !important;
  border-radius: 0.375rem !important;
  border-width: 3px !important;
}

.btn-outline-secondary:hover, .btn-outline-secondary.hover {
  background: white;
  color: black;
}

li:hover {
  background-color: #f5f5f5;
}

.content-card-wrapper {
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.content-card-wrapper.previous-selected {
  border-width: 2px;
  border-color: #d3d3d3;
}
.content-card-wrapper.selected {
  border-width: 2px;
  border-color: #76bc21;
}

.content-card-header {
  padding-bottom: 5px;
}

.dropdown-custom {
  min-width: 200px;
}
.dropdown-custom button {
  border-color: #caced5;
  border-radius: 0;
}

.header-divider {
  border-bottom: 1px solid #76bc21;
  margin-bottom: 10px;
}

.icon-link-text {
  text-decoration-line: underline;
}

.icon-only {
  font-size: 20px;
  color: #76bc21;
  margin-right: 9px;
}

.circle-icon-only {
  position: fixed;
  right: 401px;
  bottom: 1124px;
}

.status-indicator {
  height: 10px;
  width: 10px;
}

.content-card-header.row {
  padding-right: 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
