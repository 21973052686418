import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe, NgComponentOutlet, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgBusyModule } from 'ng-busy';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { SortablejsModule } from '@everymundo/ngx-sortablejs';

import { ContentCardModule } from '../../@itc-core/components/components/content-card/common/content-card.module';
import { RouteNavigationMenuModule } from '../../@itc-core/components/navigation-panels/route-navigation-menu/common/route-navigation-menu.module';
import { ItcSplitButtonDropdownModule } from '../../@itc-core/components/split-button-dropdown/common/split-button-dropdown.module';
import { ItcColouredDotModule } from '../../@itc-core/directives/itc-coloured-dot/common/itc-coloured-dot.module';
import { ItcModalDirectiveModule } from '../../@itc-core/directives/modal/common/modal.module';
import { ProjectSearchDoughnutChartModule } from '../components/charts/components/project-search-doughnut-chart/project-search-doughnut-chart.module';
import { IconButtonModule } from '../components/icon-button/common/icon-button.module';
import { ProductTourModule } from '../components/product-tour/common/product-tour.module';
import { ProjectSearchBarComponent } from '../components/project-search-bar/project-search-bar.component';
import { CommentPreviewComponent } from '../components/project/components/comment-preview/comment-preview.component';
import { ProjectTimelineComponent } from '../components/project/components/project-timeline/project-timeline.component';
import { ProjectStatusBarModule } from '../components/project/project-status-bar/common/project-status-bar.module';
import { PipeExportModule, SnackBarHelperComponent } from '../helpers';
import { DashboardModule } from '../pages/dashboard/dashboard.module';
import { ProjectModule } from '../pages/project/common/project.module';
import { ProjectCardComponent } from '../pages/project/project-search/project-card/project-card.component';
import { SentenceCasePipe } from '../pipes/sentence-case-pipe';
import { DatePipeService } from '../services/date-pipe.service';
import { ScrollService } from '../services/scroll-service';
import {
	AppLoaderComponent,
	BoxedListItemComponent,
	ChargeSubLocationAutocompletesComponent,
	CoatingSystemLayerViewComponent,
	CollapsableListComponent,
	CommunicationComponent,
	DashboardHeaderMenuComponent,
	DashboardStrikeRateComponent,
	DisplayInputNumberComponent,
	DocViewerComponent,
	FormErrorComponent,
	GenericCardsComponent,
	GenericPaginationComponent,
	GenericTableComponent,
	HeaderComponent,
	HeaderMenuComponent,
	HelpCardComponent,
	ModalComponent,
	NotificationBellComponent,
	PageFooterComponent,
	RouteNavigationContainerComponent,
} from './components';
import { ConfirmClickDirective, DisableControlDirective, MultiDragDropComponent, NumberInputDirective } from './directives';
import { CardComponent } from '../../@mos-core/components/card/card.component';
import { TranslocoDatePipe } from '@jsverse/transloco-locale';
import { TranslocoModule } from '@jsverse/transloco';

const components = [
	AppLoaderComponent,
	CollapsableListComponent,
	CommunicationComponent,
	DashboardStrikeRateComponent,
	BoxedListItemComponent,
	RouteNavigationContainerComponent,
	PageFooterComponent,
	GenericPaginationComponent,
	DisplayInputNumberComponent,
	GenericTableComponent,
	HeaderComponent,
	DashboardHeaderMenuComponent,
	HeaderMenuComponent,
	HelpCardComponent,
	CoatingSystemLayerViewComponent,
	DocViewerComponent,
	GenericCardsComponent,
	ProjectCardComponent,
	ProjectSearchBarComponent,
	ModalComponent,
	MultiDragDropComponent,
	FormErrorComponent,
	SnackBarHelperComponent,
	NotificationBellComponent,
	ProjectTimelineComponent,
	CommentPreviewComponent,
];
const directives = [NumberInputDirective, ConfirmClickDirective, DisableControlDirective];

const modules = [AngularSvgIconModule, BsDropdownModule, FormsModule, NgBusyModule, NgSelectModule, ReactiveFormsModule, ItcModalDirectiveModule, IconButtonModule];

@NgModule({
	declarations: [...components, ...directives],
	imports: [
		NgBusyModule,
		CommonModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		MatAutocompleteModule,
		ItcModalDirectiveModule,
		TooltipModule,
		FontAwesomeModule,
		PipeExportModule,
		NgSelectModule,
		ItcColouredDotModule,
		ReactiveFormsModule,
		CollapseModule,
		CarouselModule,
		RouteNavigationMenuModule,
		RouterModule,
		NgxFileDropModule,
		ItcSplitButtonDropdownModule,
		NgxPaginationModule,
		FormsModule,
		AngularSvgIconModule,
		BsDropdownModule,
		DragDropModule,
		ProjectSearchDoughnutChartModule,
		ProjectStatusBarModule,
		SortablejsModule.forRoot({ animation: 150 }),
		TypeaheadModule,
		MatIconModule,
		ModalModule.forRoot(),
		ContentCardModule,
		IconButtonModule,
		ProductTourModule,
		CardComponent,
		TranslocoModule,
		TranslocoDatePipe,
	],
	exports: [...components, ...modules, ...directives],
	providers: [DatePipe, DatePipeService, ScrollService, TitleCasePipe],
})
export class CoreModule {}
