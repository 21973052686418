// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.strike-rate {
  color: #ffffff;
}
.strike-rate .capitalize-first-only:first-letter {
  text-transform: capitalize;
}
.strike-rate .default-image {
  font-size: 100px;
}
.strike-rate .profile-image {
  border: 10px solid #76bc21;
  border-radius: 50%;
  height: 180px;
  width: 180px;
}
.strike-rate .progress-bar {
  background-color: #76bc21;
  height: 25px;
  border-radius: 15px;
}
.strike-rate .progress-bar-border {
  border: 1px solid #ffffff;
  width: 330px;
  border-radius: 15px;
}
.strike-rate .strike-rate-number {
  font-size: 100px;
  font-weight: bold;
}
.strike-rate .strike-rate-line1 {
  font-size: 20px;
  font-weight: bold;
}
.strike-rate .strike-rate-profit {
  font-size: 18px;
  font-weight: bold;
}
.strike-rate .strike-rate-amount-column {
  width: 45%;
}
.strike-rate .strike-rate-number-column {
  width: 35.5%;
}

.brush-image {
  object-fit: contain;
  width: 80px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
