import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Constants } from '../helpers';

@Injectable()
export class ExternalAccountingService {

	constructor(private http: HttpClient) {
	}

	public getIntegrationAuthorizationRedirect(integrationProvider: string) {
		return this.http
			.get<any>(`${Constants.BASE_API_URL}/external-accounting/authorize-app`, {
				params: { service: integrationProvider },
			})
			.pipe(
				map(res => {
					return !!res.redirect ? res.redirect : undefined;
				}),
			);
	}

	public updateXeroAuthorization(code, scope, state, session_state) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/external-accounting/update-authorization`, {
				params: {
					service: 'xero',
					code,
					scope,
					state,
					session_state,
				},
			})
			.pipe(
				map(res => {
					return res;
				}),
			);
	}

	public getConfig() {
		return this.http
			.get<any>(`${Constants.BASE_API_URL}/external-accounting/config`)
			.pipe(
				map(res => {
					return res;
				}),
			);
	}

	public disconnectService(integrationProvider) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/external-accounting/disconnect-authorization`, {})
			.pipe(
				map(res => {
					return res;
				}),
			);
	}

	public syncClients() {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/external-accounting/sync-clients`, {})
			.pipe(
				map(res => {
					return res;
				}),
			);
	}

	public syncSuppliers() {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/external-accounting/sync-suppliers`, {})
			.pipe(
				map(res => {
					return res;
				}),
			);
	}

	public exportInvoice(invoiceId) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/external-accounting/export-invoice`, {invoiceId})
			.pipe(
				map(res => {
					return res;
				}),
			);
	}

	public clearXeroIds() {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/external-accounting/clear-local-ids`, {})
			.pipe(
				map(res => {
					return res;
				}),
			);
	}

	public importClients() {
		return this.http.post<any>(`${Constants.BASE_API_URL}/external-accounting/import-clients`, {});
	}
}
