// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.align-summary-input {
  margin-right: -13px;
}

.dark-backdrop {
  z-index: 0;
  width: 100%;
  height: 650px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #414448;
}

.dark-backdrop-bottom {
  margin: 0 -2rem;
  /* add back negative margin value */
  padding: 0 2rem;
  margin-top: -900px;
  height: 1000px;
  background-color: #414448;
}

.dot {
  width: 1.5rem;
  height: 1.5rem;
}

.footer {
  z-index: 1;
}

.grey-square {
  background-color: #85959f;
  height: 2rem;
  width: 2rem;
}

.image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.image-container {
  height: 2rem;
  width: 2rem;
}

.overview-card-list {
  height: 15rem;
  overflow-y: auto;
}

.main-container {
  padding-bottom: 4rem;
}

.total-summary {
  color: #ffffff;
}

.truncate-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fill-vertical-height::ng-deep .content-card-wrapper {
  height: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
