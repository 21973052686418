// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
::ng-deep .modal-dialog {
  width: auto !important;
  max-width: max-content;
}

.modal-body {
  max-height: 75vh;
  overflow-y: auto;
}

.modal-container {
  padding: 20px;
}
.modal-container .modal-header {
  border-bottom: 0px;
  padding-bottom: 12px;
  align-items: center;
}
.modal-container .modal-header .close {
  font-size: 40px;
  font-weight: 500;
  color: grey;
  outline: none;
  opacity: 1;
}
.modal-container .text-disabled {
  color: grey;
}

.mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
