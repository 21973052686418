// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.scrollable {
  max-height: 75vh;
  overflow-y: auto;
}

.modal-container {
  margin: 5px 22px 22px 22px;
}
.modal-container .modal-header {
  border-bottom: 1px solid #76bc21;
}
.modal-container .modal-header .close {
  font-size: 32px;
  font-weight: 300;
  color: #414448;
  outline: none;
  opacity: 1;
}
.modal-container .btn-danger {
  margin-right: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
