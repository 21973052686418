// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-location-input {
  caret-color: black;
  line-height: normal;
  font-size: 13px !important;
  font-family: "Open Sans", sans-serif !important;
}

.close-icon {
  font-size: 14px;
}

::ng-deep .cdk-overlay-container {
  z-index: 9999;
}

:host ::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: transparent !important;
  border: 0 !important;
}

mat-form-field {
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 2px;
}
mat-form-field .mat-mdc-form-field-infix {
  padding: 0 !important;
}
mat-form-field .ng-invalid:not(form):not(.disable-error).ng-touched,
mat-form-field .submitted .ng-invalid {
  box-shadow: none !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
