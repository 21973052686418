// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.list-item-container {
  background-color: #efefef;
  border-radius: 3px;
  padding: 0.8rem 1rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
