// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.timeline {
  height: 100%;
}

.visibility-indicator {
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  font-weight: 500;
  margin-right: 0.25rem;
}
.visibility-indicator.internal {
  background-color: #f9fafb;
  color: #4b5563;
  box-shadow: inset 0 0 0 1px rgba(107, 114, 128, 0.1);
}
.visibility-indicator.external {
  background-color: #fefce8;
  color: #854d0e;
  box-shadow: inset 0 0 0 1px rgba(217, 119, 6, 0.2);
}

.add-comment-btn {
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  font-weight: 500;
  margin-right: 0.25rem;
}

.timeline-avatar {
  position: relative;
  margin-top: 0.75rem;
  height: 1.75rem;
  width: 1.75rem;
  flex: none;
  border-radius: 50%;
  background-color: #ededed;
  z-index: 2;
}
.timeline-avatar svg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #6b7280;
}

.timeline-no-avatar {
  display: flex;
  height: 1.75rem;
  width: 1.75rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ededed;
  z-index: 2;
  padding: 0.25rem;
}
.timeline-no-avatar svg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #6b7280;
}

.text-area {
  width: 100%;
  border-radius: 0.75rem;
  padding: 0.75rem;
  box-shadow: inset 0 0 0 1px rgba(211, 211, 211, 0.5);
  border: 1px solid inset rgb(156, 163, 175);
}
.text-area:focus-within {
  box-shadow: 0 0 0 2px #76bc21;
}

.textarea-class {
  display: block;
  width: 100%;
  resize: none;
  border: none;
  background: transparent;
  color: rgb(17, 24, 39);
}
.textarea-class::placeholder {
  color: rgb(156, 163, 175);
}
.textarea-class:focus {
  outline: none;
}
@media (min-width: 640px) {
  .textarea-class {
    font-size: 0.875rem;
    line-height: 1.5;
  }
}

.update {
  display: flex;
  position: relative;
  column-gap: 1rem;
}
.update .line-container {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 1.75rem;
  justify-content: center;
  bottom: -1.5rem;
}
.update .line-container .line {
  width: 1px;
  background-color: #e5e7eb;
}
.update .line-gap {
  position: relative;
  display: flex;
  height: 1.75rem;
  width: 1.75rem;
  flex: none;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.update .line-gap .status-dot {
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 100%;
}
.update .comment {
  flex: auto;
  border-radius: 0.75rem;
  padding: 0.75rem;
  box-shadow: inset 0 0 0 1px rgba(211, 211, 211, 0.5);
  margin-bottom: 0.75rem;
}
.update .comment .header {
  padding-top: 0.125rem;
  font-size: 0.8rem;
  line-height: 1.25rem;
  color: #9ca3af;
  gap: 0 1rem;
}
.update .comment .header .name {
  font-weight: bold;
  color: #1f2937;
}
.update .comment .header .message {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.update .comment p {
  margin-bottom: 0;
}
.update .status {
  width: 100%;
  margin-right: 0.25rem;
}
.update .status time {
  font-size: 0.8rem;
  line-height: 1.25rem;
  color: #9ca3af;
}

.comment-box {
  display: flex;
  column-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.comment-box .toggle-button {
  position: relative;
  display: inline-flex;
  height: 1.5rem;
  width: 2.75rem;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 9999px;
  border: 2px solid transparent;
  transition: colors 0.2s ease-in-out;
  outline: none;
  padding: 0;
}
.comment-box .toggle-button:focus {
  box-shadow: 0 0 0 2px rgba(102, 126, 234, 0.5);
}
.comment-box .toggle-button.enabled {
  background-color: #667eea;
}
.comment-box .toggle-button.not-enabled {
  background-color: #edf2f7;
}
.comment-box .toggle-button-knob {
  transform: translateX(0);
  pointer-events: none;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 9999px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease-in-out;
}
.comment-box .toggle-button.enabled .toggle-button-knob {
  transform: translateX(1.25rem); /* Move to the right when parent button is enabled */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
