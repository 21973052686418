import { NgModule } from '@angular/core';
import { DatePickerComponent } from './date-picker.component';
import { PopoverComponent } from '../popover/popover.component';
import { CommonModule, NgClass } from '@angular/common';
import { Calendar, LucideAngularModule, ChevronLeft, ChevronRight, XCircle } from 'lucide-angular';
import { TranslocoDatePipe } from '@jsverse/transloco-locale';

@NgModule({
	declarations: [DatePickerComponent],
	imports: [
		CommonModule,
		NgClass,
		PopoverComponent,
		LucideAngularModule.pick({ Calendar, ChevronLeft, ChevronRight, XCircle }),
		TranslocoDatePipe,
	],
	exports: [
		DatePickerComponent,
	],
})
export class DatePickerModule {}