// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.btn-primary.dropdown-toggle:focus,
.btn-primary.icon-button:focus {
  box-shadow: none !important;
}

.custom-dropdown-menu {
  border-top: 3px solid #d3d3d3;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  padding-top: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
}

.open .dropdown-toggle {
  background-color: #231f20;
}

.custom-dropdown-item {
  padding-left: 10px;
  padding-right: 10px;
}

.custom-dropdown-item:not(:last-of-type) {
  border-bottom: 1px solid #ededed;
}

.icon-button {
  border-bottom-left-radius: 18px;
  border-top-left-radius: 18px;
  border-right: 3px solid #ffffff;
  width: 40px;
  padding: 0;
}

.mat-icon-button {
  line-height: 16px;
  max-height: 33px;
}

.split-button-group .secondary-action {
  border-left: 2px solid #ffffff;
  border-radius: 0;
  padding: 0 14px;
}

.split-button-group-separate .primary-action {
  border-radius: 18px;
}
.split-button-group-separate .secondary-action {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.split-button-group-separate .caret {
  color: #696c6f;
}
.split-button-group-separate .caret:hover {
  color: #231f20;
}

.text-adjust {
  width: -moz-fit-content;
  width: fit-content;
  block-size: -moz-fit-content;
  block-size: fit-content;
}

.text-wrap {
  min-width: 215px;
}

.disabled {
  color: #caced5 !important;
  pointer-events: none;
}

.hidden {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
