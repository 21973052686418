// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
:host .coms-container {
  height: 60px;
  background-color: #76bc21;
}
:host .coms-text {
  color: #ffffff;
  line-height: 60px;
  text-align: center;
  width: 90%;
}
:host .ql-editor {
  padding: 3px 0;
}
:host .ql-editor ::ng-deep {
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host ::ng-deep .carousel-control-prev,
:host ::ng-deep .carousel-control-next {
  width: 5%;
}
:host .coms-tooltip {
  height: 100%;
  width: calc(100% - 200px);
  position: absolute;
  z-index: 1;
  margin-left: 100px;
  margin-right: 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
