import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Constants, SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../../helpers';
import { CustomFormGroup, GetUserValidation } from '../../../interfaces';
import { UserModel } from '../../../models';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-user-details-dialog',
	templateUrl: './user-details-dialog.component.html',
})
export class UserDetailsDialogComponent implements OnInit {
	@Input()
	public readonly currentUser: UserModel;

	public newUser: UserModel;
	public textConstants: typeof TEXT = TEXT;
	public SVG_ICON_PATHS = Constants.SVG_ICON_PATHS;
	public updateUserPromise: Promise<UserModel>;
	public validators: CustomFormGroup;

	constructor(
		private localStorageService: LocalStorageService,
		private snack: SnackBarHelperComponent,
		private userService: UserService
	) {}

	public ngOnInit(): void {
		this.newUser = Object.assign({}, this.currentUser);
		this.validators = GetUserValidation();
		this.newUser.language = this.newUser.language ? this.newUser.language : this.newUser.entity.address.country === 'australia' ? 'en-au' : 'en-us';
	}

	/**
	 * Updates the current user.
	 */
	public updateUser(): Observable<UserModel> {
		this.validators.Companies.setValue(this.newUser.providers);
		const errors = ValidatorHelper.checkErrors(this.validators);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}
		return this.userService.updateUser(this.newUser.id, this.newUser).pipe(
			tap((user: UserModel) => {
				this.localStorageService.updateSessionUser(user);
			})
		);
	}
}
