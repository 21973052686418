// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
:host section {
  background-color: #ffffff;
}
:host .nav-item {
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
}
:host .paint-icon-container ::ng-deep .icon.folder {
  font-size: 1.5rem;
}
:host .active {
  background-color: rgba(118, 188, 33, 0.2);
}
:host .active ::ng-deep .folder-svg {
  fill: #76bc21;
}
:host .active ::ng-deep .silhouettes-svg,
:host .active ::ng-deep .home-svg,
:host .active ::ng-deep .menu-folder-svg,
:host .active ::ng-deep .database-svg,
:host .active ::ng-deep .project-description-svg,
:host .active ::ng-deep .invoices,
:host .active ::ng-deep .calendar,
:host .active ::ng-deep .gear-svg {
  stroke: #76bc21;
}
:host .faIcon {
  font-size: 2.25rem;
}
:host .activeFaIcon {
  color: #76bc21;
}
:host .logo-container {
  position: fixed;
  bottom: 6rem;
  height: 14rem;
  width: 3rem;
}
:host .logo-image {
  height: 100%;
  object-fit: contain;
}
:host .intercom-launcher-logo {
  height: 50px;
  width: 50px;
}
:host .hover-highlight-svg:hover > svg-icon {
  fill: #414448;
}
:host .hover-highlight-faIcon:hover {
  color: #414448;
}
@media only screen and (max-height: 820px) {
  :host .logo-container {
    bottom: 40px;
  }
  :host .logo-image {
    height: 80%;
  }
}
@media only screen and (max-height: 700px) {
  :host .logo-container {
    position: inherit;
    height: 5rem;
  }
  :host .icon {
    margin-top: 1rem;
  }
  :host .logo-image {
    display: none;
  }
}
:host ::ng-deep .popover {
  border-radius: 0;
  margin-left: 0;
  width: 200px;
}
:host ::ng-deep .popover-arrow {
  display: none;
}
:host ::ng-deep .popover-body {
  border-top: 3px solid black;
  padding-top: 0;
}
:host .disabled-sidenav-button {
  pointer-events: none;
}
:host .my-scrollable-list {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
:host .my-scrollable-list::-webkit-scrollbar {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
