// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
:host ::ng-deep .modal-content {
  padding: 16px;
  width: 724px;
  margin-left: -112px;
}

.colour-letter-box {
  background-color: #d9dee8;
  width: 23%;
}

.colour-picker-sample {
  border: none;
  height: 200px;
  width: 200px;
}

.fill-space {
  padding-top: 1px;
}

.hex-colour-spacing {
  margin-bottom: 15px;
}

.rgb-input {
  border: none;
  width: 77%;
  line-height: 29px;
}

.rgb-input-line {
  border: 1px solid #d9dee8;
  line-height: 29px;
}

.rgb-spacing {
  margin-bottom: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
