import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HeaderService } from '../../core/components/header/common/header.service';
import { GetCurrentUserResolver } from '../../services/user.service';
import { MeasuringToolService } from '../measure-tool/common/measuring-tool.service';

@Component({
	templateUrl: './measuring-tool.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasuringToolComponent implements AfterContentChecked, OnDestroy {
	/**
	 * Router config resolvers
	 * @type {}
	 */
	public static routerResolvers = {
		currentUser: GetCurrentUserResolver,
	};

	public canvasLoading: Observable<boolean>;
	public isCanvasLoading: boolean = false;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private headerService: HeaderService,
		private measuringToolService: MeasuringToolService
	) {
		this.canvasLoading = this.measuringToolService.canvasLoading.pipe(
			tap((value: boolean) => {
				this.isCanvasLoading = value;
			})
		);
	}

	public ngAfterContentChecked(): void {
		this.changeDetectorRef.detectChanges();
	}

	public ngOnDestroy(): void {
		this.headerService.setIsPageTitleDefined(false);
	}
}
