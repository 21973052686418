import { provideTranslocoLocale, TranslocoDatePipe } from '@jsverse/transloco-locale';
import { provideTransloco, TranslocoModule, FunctionalTranspiler, provideTranslocoTranspiler } from '@jsverse/transloco';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
import { environment } from '../environments/environment';

@NgModule({
	exports: [TranslocoModule,],
	providers: [
		provideTranslocoTranspiler(FunctionalTranspiler),
		provideTranslocoLocale({
			langToLocaleMapping: {
				'en-au': 'en-AU',
				'en-us': 'en-US',
				es: 'en-ES',
			},
		}),
		provideTransloco({
			config: {
				availableLangs: ['en-au', 'en-us', 'es'],
				defaultLang: 'en-au',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: environment.production,
			},
			loader: TranslocoHttpLoader,
		}),
	],
})
export class TranslocoRootModule {
}
