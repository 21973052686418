// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
:host {
  width: 100%;
}

.header-container {
  background-color: #efefef;
}

.expanded-margin {
  transition: 300ms ease-out;
  margin-left: 217px;
}

.collapsed-margin {
  transition: 300ms ease-out;
}

.header-menu {
  position: relative;
  top: -3px;
}

.status-coloured-dot {
  height: 15px;
  width: 15px;
}

.header-position {
  margin-left: 30px;
  margin-top: -23px;
}

.header-text {
  font-size: 26px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
