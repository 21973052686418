import { NgClass } from '@angular/common';
import { Component, ElementRef, HostListener, Input } from '@angular/core';
import {PopoverService} from "../../../app/services/popover.service";

@Component({
	selector: 'mos-popover',
	exportAs: 'mosPopover',
	templateUrl: './popover.component.html',
	styleUrl: './popover.component.css',
	imports: [NgClass],
	standalone: true,
})
export class PopoverComponent {
	@Input() public buttonText: string;
	@Input() public position?: 'top' | 'bottom' | 'left' | 'right';
	@Input() public align?: 'start' | 'middle' | 'end';
	public isOpen = false;
	public popoverData: any;

	constructor(private elementRef: ElementRef, private popoverService: PopoverService) {}

	public open(event: MouseEvent, popoverData?: any): void {
		event.preventDefault();
		event.stopPropagation();
		this.isOpen = !this.isOpen;
		this.popoverData = popoverData;
		if (this.isOpen) {
			this.popoverService.register(this);
		} else {
			this.popoverService.unregister(this);
		}
	}

	public dynamicClasses() {
		const classes = { 'tw-hidden': !this.isOpen };
		if (this.position) {
			classes['popover-' + this.position] = true;
		}
		if (this.align) {
			classes[`align-${this.align}-${this.position}`] = true;
		}
		return classes;
	}

	public close() {
		this.isOpen = false;
		this.popoverService.unregister(this);
	}

	public toggle(event: MouseEvent, popoverData?: any): void {
		if (this.isOpen) {
			this.close();
		} else {
			this.open(event, popoverData);
		}
	}

	@HostListener('document:click', ['$event'])
	public clickOutside(event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.close();
		}
	}
}
