// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-container {
  position: relative;
  transition: width var(--sidebar-transition-time) ease-in-out;
  z-index: 50;
}
.sidebar-container.collapsed {
  width: var(--sidebar-width-collapsed);
}
.sidebar-container.expanded {
  width: var(--sidebar-width-expanded);
}

.icon-wrapper {
  position: absolute;
  top: 15px;
  right: 0;
  transform: translateX(calc(100% - 10px));
  transition: transform var(--sidebar-transition-time) ease-in-out;
  z-index: 60;
}

.sidebar-content {
  position: relative;
  z-index: 55;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
