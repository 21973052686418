// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
:host ::ng-deep .content-card-wrapper .charge-calculation-ng-busy {
  overflow: auto;
  max-height: calc(100vh - 315px) !important;
}
:host .filter-section ::ng-deep .mat-select-placeholder {
  color: #aab2c0;
}
:host ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-top: 16px;
}
:host ::ng-deep .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
:host .ng-select::ng-deep .ng-select-container {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
:host ::ng-deep .ng-clear-wrapper {
  margin-left: 10px;
}
:host ::ng-deep .ng-select .ng-select-container .ng-value-container {
  padding-left: 0;
}

.comments-edit-wrapper {
  height: 190px;
  width: 700px;
}

.coloured-dot {
  height: 35px;
  width: 35px;
}

.charge-table {
  display: table;
  margin-bottom: 0;
}

.checkbox-wrapper {
  height: 15px;
}

.group-header {
  background: #efefef;
  border-top: 1px solid #dee2e6;
}

.add-icon {
  color: #76bc21;
  font-size: 50px;
}

.add-icon-btn:focus {
  outline: none;
}

.icons {
  height: 20px;
  width: 20px;
}

.mat-form-field {
  font-size: 14px;
}

.table-header > span,
.table-summary-headers > span,
.table-summary-totals > span {
  vertical-align: top;
  white-space: normal;
}

.table-header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-footer {
  background-color: white;
  color: #76bc21;
  position: sticky;
  bottom: -1px;
}

.table-row {
  border-bottom: 1px solid #dee2e6;
  min-height: 52px;
}

.table-summary-totals {
  border-top: 2px solid #dee2e6;
}

.sticky-row {
  top: 60px;
  background: #ffffff;
}

.location,
.substrate,
.coating,
.colour {
  vertical-align: top;
}

.location,
.substrate,
.coating,
.colour,
.difficulty,
.labour,
.units,
.cost-unit,
.cost,
.rate,
.charge,
.profit,
.gp,
.discount {
  width: 7.6%;
  padding-right: 10px;
  display: table-cell;
  white-space: normal;
  word-break: break-word;
}

.discount {
  padding-right: 0;
}

.location {
  min-width: 270px;
}
.location .index {
  width: 120px;
  vertical-align: top;
}
.location .location-label {
  width: calc(100% - 120px);
}

.substrate {
  min-width: 100px;
}

.coating {
  min-width: 100px;
}

.colour {
  min-width: 75px;
}

.difficulty {
  min-width: 110px;
}

.labour {
  min-width: 100px;
}

.units {
  min-width: 120px;
}
.units app-display-input-number {
  display: inline-block;
  margin-right: 40px;
}
.units .unit-type {
  margin-left: -40px;
}

.cost-unit {
  min-width: 100px;
}

.cost {
  min-width: 120px;
}

.rate {
  min-width: 120px;
}

.charge {
  min-width: 120px;
}

.profit {
  min-width: 100px;
}

.gp {
  min-width: 100px;
}

.discount {
  min-width: 100px;
}

.button-group {
  margin-top: 4.3rem;
}

.imitate-angular-material {
  border-bottom: 1px solid #76bc21;
}

.company-select-label {
  font-size: 15px;
  font-weight: 600 !important;
}

.reorder-button {
  position: absolute;
  outline: none;
  right: 10px;
  top: -12px;
}

.select-all-checkbox {
  position: absolute;
  top: 4px;
}

.last-edited {
  border-left: solid #76bc21 5px;
}

.disabled-icon {
  opacity: 0.3;
  pointer-events: none;
}

.svg-icon-edit svg {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
