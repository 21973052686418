// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
:host::ng-deep .popover {
  border-radius: 0;
  margin-left: 0;
  width: 400px;
  max-width: 400px;
}
:host::ng-deep .popover-arrow {
  display: none;
}
:host::ng-deep .popover-body {
  padding-top: 0;
  padding-right: 0;
  max-height: 425px;
}
:host::ng-deep .unread {
  border-top: 4px solid #f77f21;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
