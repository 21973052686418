import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

import { Observable, Subscription } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { Text } from '../../../../@itc-core/common/text.constants';

import { ContentCardModel } from '../../../../@itc-core/components/components/content-card/common/content-card.models';
import { ValueSummary } from '../../../../@itc-core/components/components/value-summary-box/common/value-summary.interface';
import { NavigationWarningService } from '../../../../@itc-core/components/navigation-warning/common/navigation-warning.service';
import { projectStatuses } from '../../../components/project/common/project.constants';
import { EditProjectStatusDialogComponent } from '../../../dialog/components';
import { ClientHelper, Constants, LOCAL_STORAGE_KEYS, SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../../helpers';
import {
	Company,
	ContactClient,
	GetAddressValidation,
	GetDeliveryAddressValidationWhenSameAsBilling,
	GetDiscountOptionsValidation,
	GetProjectSummaryDiscountValidation,
	GetProjectValidation,
	GetTaskValidation,
	LabourCost,
	LabourRate,
	Project,
	ProjectSaveObj,
	ReferenceCode,
	State,
} from '../../../interfaces';
import { UserModel } from '../../../models';
import { AdditionalChargeService } from '../../../services/additional-charge.service';
import { AuthService } from '../../../services/auth.service';
import { BoqService } from '../../../services/boq.service';
import { BrandService } from '../../../services/brand.service';
import { CalculationService } from '../../../services/calculation.service';
import { GetActiveClientsResolver } from '../../../services/client.service';
import { ColourService } from '../../../services/colour.service';
import { GetActiveProvidersResolver } from '../../../services/company.service';
import { ExportService } from '../../../services/export.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { GetMarketingItemsResolver } from '../../../services/marketing.service';
import { ModalService } from '../../../services/modal.service';
import { NavigationService } from '../../../services/navigation.service';
import { ProjectSessionService } from '../../../services/project-session.service';
import { GetTotalTimeSpentOnProject } from '../../../services/project-time.service';
import { GetCloneProjectResolver, GetProjectResolver, ProjectService } from '../../../services/project.service';
import { ActiveRefCodeWithTypesResolver, RefCodeService } from '../../../services/reference-code.service';
import { S3Service } from '../../../services/s3.service';
import { ScrollService } from '../../../services/scroll-service';
import { StateService } from '../../../services/state.service';
import { TaskService } from '../../../services/task.service';
import { UserGroupService } from '../../../services/user-group.service';
import { GetCurrentUserResolver, GetUsersResolver, UserService } from '../../../services/user.service';
import { AddEditProjectValidators } from './add-edit-project/common/add-edit-project.interface';
import { ProjectDetailsTextConst } from './common/project-details-text-constants';
import { IncExcCommentsComponent } from './inc-exc-comments/inc-exc-comments.component';
import { ProjectDetailsTabsComponent } from './project-details-tabs/project-details-tabs.component';
import { ProjectTasksComponent } from './project-tasks/project-tasks.component';
import { SelectedProjectService } from '../../../services/select-project.service';
import { environment } from '../../../../environments/environment';
import posthog from 'posthog-js';

@Component({
	selector: 'app-project-details',
	templateUrl: './project-details.component.html',
	styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
	constructor(
		public snack: SnackBarHelperComponent,
		private activatedRoute: ActivatedRoute,
		private additionalChargeService: AdditionalChargeService,
		private authService: AuthService,
		private boqService: BoqService,
		private brandService: BrandService,
		private calculationService: CalculationService,
		private currencyPipe: CurrencyPipe,
		private colourService: ColourService,
		private exportService: ExportService,
		private localStorageService: LocalStorageService,
		private modalService: ModalService,
		private navWarningService: NavigationWarningService,
		private decimalPipe: DecimalPipe,
		private navigationService: NavigationService,
		private percentPipe: PercentPipe,
		private projectService: ProjectService,
		private projectSessionService: ProjectSessionService,
		private refCodeService: RefCodeService,
		private router: Router,
		private s3Service: S3Service,
		private scrollService: ScrollService,
		private stateService: StateService,
		private taskService: TaskService,
		private userService: UserService,
		private userGroupService: UserGroupService,
		private selectedProjectService: SelectedProjectService
	) {}

	/**
	 * Router config for edit resolvers
	 * @type {}
	 */
	public static routerEditResolvers = {
		clients: GetActiveClientsResolver,
		companies: GetActiveProvidersResolver,
		currentUser: GetCurrentUserResolver,
		project: GetProjectResolver,
		cloneProject: GetCloneProjectResolver,
		referenceCodeTypes: ActiveRefCodeWithTypesResolver,
		totalTimeSpentOnProject: GetTotalTimeSpentOnProject,
		marketingItems: GetMarketingItemsResolver,
		users: GetUsersResolver,
	};

	public static routerAddEditProjectResolver = {
		referenceCodeTypes: ActiveRefCodeWithTypesResolver,
		currentUser: GetCurrentUserResolver,
	};

	public static routerEditResolversSearch = {
		//clients: GetActiveClientsResolver,
		companies: GetActiveProvidersResolver,
		currentUser: GetCurrentUserResolver,
		project: GetProjectResolver,
		cloneProject: GetCloneProjectResolver,
		referenceCodeTypes: ActiveRefCodeWithTypesResolver,
		totalTimeSpentOnProject: GetTotalTimeSpentOnProject,
		marketingItems: GetMarketingItemsResolver,
		users: GetUsersResolver,
	};

	/**
	 * Router config for data
	 * @type {}
	 */
	public static routerData = {
		resolverCodeTypes: ['additional_charge', 'labour_type', 'profile', 'substrate'],
	};
	@ViewChild('chargesTabContainer')
	public chargesTabContainer: ElementRef;
	@ViewChild(EditProjectStatusDialogComponent, { static: false })
	public editProjectStatusDialogComponent: EditProjectStatusDialogComponent;
	@ViewChild('editStatus', { static: true })
	public editStatusRef: TemplateRef<any>;
	@ViewChild('exportTabSetContainer')
	public exportTabSetContainer: ElementRef;
	@ViewChild(IncExcCommentsComponent, { static: false })
	public incExcCommentsComponent: IncExcCommentsComponent;
	@ViewChild(ProjectDetailsTabsComponent, { static: false })
	public projectDetailsTabs: ProjectDetailsTabsComponent;
	@ViewChild(ProjectTasksComponent, { static: false })
	public projectDetailsTasksComponent: ProjectTasksComponent;
	@ViewChild(ProjectTasksComponent, { static: false })
	public projectTasksComponent: ProjectTasksComponent;

	@ViewChild('chargesTabset', { static: false })
	private chargesTabsetComponent: TabsetComponent;
	@ViewChild('exportTabset', { static: false })
	private exportTabsetComponent: TabsetComponent;

	public activatedRouteData: Subscription;
	public activatedRouteParams: Subscription;
	public addProjectSubscription: Subscription;
	public clientCardInputConfig: ContentCardModel;
	public clientHelper = new ClientHelper();
	public clients = [];
	public clonedProject: Project = undefined;
	public currentUser: UserModel = undefined;
	public currentUserIsTeamLeader: boolean = false;
	public datesCardInputConfig: ContentCardModel;
	public discountOptionsValidators;
	public discountShowInExport = false;
	public discountTypeCurrent = Constants.DISCOUNT_TYPES.onProject;
	public errorStatus: boolean = false;
	public formState: string = Constants.FORM_STATES.VIEW;
	public formSubmitted: boolean = false;
	public initialDecisionDate: Date;
	public initialQuoteDueDate: Date = undefined;
	public initialStatus: string;
	public isAdmin: boolean;
	public isDirty = false;
	public isDisabled: boolean;
	public isEditDisabled: boolean = false;
	public isFormInError = false;
	public isNotGenerating: boolean;
	public isRequestingSave: boolean;
	public labourCost: LabourCost;
	public labourTypesEdit: ReferenceCode[];
	public PROJECT_CALCULATION_FIELDS = Constants.PROJECT_CALCULATION_FIELDS;
	public PROJECT_STATUS = Constants.PROJECT_STATUS;
	public project: Project;
	public projectDetailsFormGroup;
	public projectDetailsViewValidators;
	public projectStatuses: typeof projectStatuses = projectStatuses;
	public projectValidators: AddEditProjectValidators;
	public providers: Company[] = [];
	public rates: LabourRate[];
	public regions = [];
	public scopeCardInputConfig: ContentCardModel;
	public siteAddressCardInputConfig: ContentCardModel;
	public states = [];
	public subscription: Subscription = new Subscription();
	public substrates: ReferenceCode[];
	public SVG_ICON_PATHS = Constants.SVG_ICON_PATHS;
	public taskValidator;
	public text: typeof ProjectDetailsTextConst = ProjectDetailsTextConst;
	public totalNetGstSummary: ValueSummary[];
	public totalTimeSpentOnProject: number;
	public updateProjectSubscription: Subscription;
	public users: UserModel[] = [];
	private constants: typeof Text = Text;

	public ngOnInit(): void {
		this.setUpCardConfigs();
		const stateList = this.stateService
			.postList({ isActive: true })
			.pipe(finalize(() => stateList.unsubscribe()))
			.subscribe((states: State[]) => {
				this.states = states;
			});
		this.taskValidator = GetTaskValidation();
		this.projectDetailsViewValidators = GetProjectSummaryDiscountValidation();
		this.discountOptionsValidators = GetDiscountOptionsValidation();
		this.resetNewProject();
		this.isAdmin = this.authService.isAdmin();
		this.isNotGenerating = true;
		this.isDisabled = true;
		this.initialStatus = '';
		this.projectValidators = {
			nonAddressValidators: GetProjectValidation(this.project),
			billingValidators: GetAddressValidation(),
			deliveryValidators: this.project.isDeliveryAddressSame ? GetDeliveryAddressValidationWhenSameAsBilling() : GetAddressValidation(),
			siteValidators: GetAddressValidation(),
		};
		this.setDataFromActivatedRoute();
	}

	public ngAfterViewInit(): void {
		setTimeout(() => {
			// Open the charges tab when editing existing projects
			if (this.project.id) {
				this.chargesTabsetComponent.tabs[0].active = true;
			}
			this.activatedRouteParams = this.activatedRoute.queryParamMap.subscribe(params => {
				// without timer it does not fire consistently
				// @ts-ignore
				switch (params.params.scrollToEl) {
					case TEXT.attachments:
						this.exportTabsetComponent.tabs[2].active = true;
						this.scrollService.scrollToElement(this.exportTabSetContainer.nativeElement);
						this.clearQueryScrollToElParams();
						break;
					case TEXT.charges:
						this.scrollService.scrollToElement(this.chargesTabContainer.nativeElement);
						this.clearQueryScrollToElParams();
						break;
				}
			});
		}, 0);
	}

	public ngOnDestroy(): void {
		if (this.activatedRouteParams) {
			this.activatedRouteParams.unsubscribe();
		}
		if (this.activatedRouteData) {
			this.activatedRouteData.unsubscribe();
		}
	}

	/**
	 * Add or edit the project
	 * according the form state
	 */
	public addOrEditProject(): Promise<void> {
		this.formSubmitted = true;
		this.updatePageEditedAndValidation();
		if (this.errorStatus) {
			return;
		}

		this.isRequestingSave = false;

		//Check if we are in insert mode or edit mode
		if (this.formState === Constants.FORM_STATES.ADD) {
			this.cloneOrAddProject();
		} else if (this.formState !== Constants.FORM_STATES.ADD) {
			this.saveProject(this.project);
		}
		this.navWarningService.setStatus(false);
	}

	public addOrEditQuoteDueDateTask(project): void {
		if (project.quoteDueDate && project.quoteDueDate !== this.initialQuoteDueDate) {
			// Create or update "Quote due date" task.
			this.projectTasksComponent.addOrEditQuoteDueDateTask(project, this.currentUser);
			this.updateInitialQuoteDueDate(this.project.quoteDueDate);
		}
	}

	/**
	 * Returns the user to the projects page when adding a project.
	 * Resets the form back to its initial state in edit mode.
	 */
	public onCancel(): void {
		this.navigationService.setRoute(['/projects']);
	}

	/**
	 * reset the form after insert a new project
	 * or after finish editing an existing project.
	 */
	public resetNewProject(): void {
		this.project = new Project({
			name: undefined,
			division: undefined,
			isActive: true,
			isDiscountPercentage: false,
			status: this.PROJECT_STATUS.target.value,
			discountType: Constants.DISCOUNT_TYPES.onProject,
			discount: 0,
			discountPercentage: 0,
			additionalChargesCost: 0,
			additionalChargesChargeOut: 0,
			paymentTerms: '30',
			probability: 0,
			materialMarkUp: 20,
			wasteToConsumables: 10,
			gst: 10,
			isDiscountDisabled: false,
			totalProfit: 0,
		});
		this.isEditDisabled = false;
	}

	public saveProject(project: Project): void {
		this.setCalculatedFields(project);

		// Setup form group with any new forms
		// When saving the edit, stay on current page, just set the form state back to view
		this.setupFormGroup();
		this.updateProjectSubscription = this.projectService
			.editProject(new ProjectSaveObj(this.project))
			.pipe(
				finalize(() => {
					this.updateProjectSubscription.unsubscribe();
				})
			)
			.subscribe(savedProject => {
				this.setProject(savedProject);
				this.formState = Constants.FORM_STATES.VIEW;
				this.initialStatus = savedProject.status; // update the initial status.
				this.addOrEditQuoteDueDateTask(this.project); // creates a new task only if there is a dueDate.
				this.populateEstimatorPicture();
				this.populateClientsPicture();
			});
	}

	public setCalculatedFields(calculatedProject: Project): void {
		//this.project.chargeOrder = calculatedProject.chargeOrder;

		// Set all calculation related fields that are stored on the project
		for (const field of this.PROJECT_CALCULATION_FIELDS) {
			this.project[field] = calculatedProject[field];
		}
	}

	public setClonedProject(clonedProject): void {
		this.clonedProject = clonedProject;
		this.cloneProject(clonedProject);
		this.navigationService.removeQueryParam('cloneProjectId');
	}

	public setProject(project: Project): void {
		this.project = project;
		this.isEditDisabled = !this.authService.canEditProject(project, this.currentUser);
		this.clients = this.project.clients;
	}

	public setupFormGroup(): void {
		//Add our default validators to the group
		if (!this.projectDetailsFormGroup) {
			this.projectDetailsFormGroup = new UntypedFormGroup(this.projectDetailsViewValidators);
			this.projectDetailsFormGroup.addControl('ProjectTotal', this.discountOptionsValidators.ProjectTotal);
			this.projectDetailsFormGroup.addControl('BOQEvenly', this.discountOptionsValidators.BOQEvenly);
			this.projectDetailsFormGroup.addControl('BOQIndividually', this.discountOptionsValidators.BOQIndividually);
		}
		this.projectDetailsFormGroup.pristine = true;
		this.isDirty = false;
	}

	public updatePageEditedAndValidation(): void {
		this.navWarningService.setStatus(true);
		if (this.formSubmitted) {
			const clientsInvalid = !this.clientHelper.areClientsValid(this.project);
			const hasErrors = this.projectValidatorsCheckErrors(this.projectValidators) || clientsInvalid;

			// @ts-ignore
			// This could technically be done by accessing a isSubmitted boolean on the add-edit component with a viewchild
			this.projectValidators.nonAddressValidators.isSubmitted = true;
			this.errorStatus = hasErrors;
			if (hasErrors) {
				this.snack.snackError(this.constants.createProjectError);
				return;
			}
		}
	}

	private projectValidatorsCheckErrors(projectValidators: AddEditProjectValidators): boolean {
		return (
			(ValidatorHelper.checkErrors(projectValidators.nonAddressValidators) !== undefined && ValidatorHelper.checkErrors(projectValidators.nonAddressValidators) !== '') ||
			(ValidatorHelper.checkErrors(projectValidators.billingValidators) !== undefined && ValidatorHelper.checkErrors(projectValidators.billingValidators) !== '' && !this.project.isBillingAddressSame) ||
			(ValidatorHelper.checkErrors(projectValidators.deliveryValidators) !== undefined &&
				ValidatorHelper.checkErrors(projectValidators.deliveryValidators) !== '' &&
				!this.project.isDeliveryAddressSame) ||
			(ValidatorHelper.checkErrors(projectValidators.siteValidators) !== undefined && ValidatorHelper.checkErrors(projectValidators.siteValidators) !== '')
		);
	}

	public updateInitialQuoteDueDate(date: Date): void {
		this.initialQuoteDueDate = date;
	}

	private checkUserTeamLeaderStatus(): void {
		const list = this.userGroupService
			.postList({
				params: {
					teamLeader: this.currentUser.id,
				},
			})
			.pipe(finalize(() => list.unsubscribe()))
			.subscribe(res => {
				this.currentUserIsTeamLeader = res.length > 0;
			});
	}

	private clearQueryScrollToElParams(): void {
		this.router.navigate([], {
			queryParams: {
				scrollToEl: null,
			},
			queryParamsHandling: 'merge',
			replaceUrl: true,
		});
	}

	private cloneOrAddProject(): void {
		let cloneOrAddProjectObservable: Observable<Project>;
		if (this.clonedProject) {
			cloneOrAddProjectObservable = this.projectService.cloneProject(this.clonedProject.id, new ProjectSaveObj(this.project));
		} else {
			cloneOrAddProjectObservable = this.projectService.addProject(new ProjectSaveObj(this.project));
		}
		let newProject;
		this.addProjectSubscription = cloneOrAddProjectObservable
			.pipe(
				switchMap(project => {
					newProject = project;
					// We can assume there will be no need to complete any existing project sessions
					// as the project was just created
					return this.projectSessionService.startProjectSession(project.id, this.currentUser.id);
				}),
				tap(() => {
					this.localStorageService.setUserKey(LOCAL_STORAGE_KEYS.entity, newProject.entity);
					//Return back to the project page
					this.navigationService.setRoute(['/project', newProject.id]);
					this.selectedProjectService.setSelectedProject(newProject);
					if (environment.posthog) {
						posthog.capture('Project Created', {
							project: newProject.id,
							entity: newProject.entity,
							division: newProject.division,
						});
					}
				}),
				finalize(() => {
					this.addProjectSubscription.unsubscribe();
				})
			)
			.subscribe();
	}

	/**
	 * Clones the given project by copying only the required fields in the add project page.
	 * @param project
	 */
	private cloneProject(project: Project): void {
		for (const key in project) {
			if (project.hasOwnProperty(key) && Constants.CLONE_PROJECT_FIELDS[key]) {
				this.project[key] = project[key];
			}
		}
	}

	private populateClientsPicture(): void {
		if (this.project.clients) {
			this.project.clients.map((client: ContactClient) => {
				if (client.contact) {
					if (client.contact.displayPictureUrl) {
						client.contact.imageUrl = client.contact.displayPictureUrl;
					} else if (client.contact.displayPictureKey) {
						const subscription = this.s3Service
							.getSignedUrl(client.contact.displayPictureKey)
							.pipe(finalize(() => subscription.unsubscribe()))
							.subscribe(url => {
								client.contact.imageUrl = url;
							});
					}
				}
			});
		}
	}

	private populateEstimatorPicture(): void {
		if (this.project.estimator && this.project.estimator.displayPictureKey) {
			const subscription = this.s3Service
				.getSignedUrl(this.project.estimator.displayPictureKey)
				.pipe(finalize(() => subscription.unsubscribe()))
				.subscribe(url => {
					this.project.estimator.imageUrl = url;
				});
		}
	}

	private setDataFromActivatedRoute(): void {
		this.activatedRouteData = this.activatedRoute.data.subscribe(
			async ({ cloneProject, project, rates, users, companies, referenceCodeTypes, totalTimeSpentOnProject, currentUser }) => {
				this.currentUser = currentUser;
				this.labourTypesEdit = referenceCodeTypes.labour_type;
				// Get if user is a team leader
				this.checkUserTeamLeaderStatus();
				this.rates = rates;
				this.users = users;
				this.providers = companies;
				this.totalTimeSpentOnProject = Math.round(totalTimeSpentOnProject / 360000) / 10; // Convert to hours

				// In order to edit the input file button, the whole thing has to be changed, and this has to be assigned to
				// a variable to display on the page
				//when editing a project
				//TODO to make a api call to get all the regions not to populate from project level
				if (project) {
					this.setProjectDetails(project);
				} else {
					this.formState = Constants.FORM_STATES.ADD;
					if (cloneProject) {
						this.setClonedProject(cloneProject);
					}
				}
			}
		);
	}

	private setProjectDetails(project: Project): void {
		if (project.addressDetail.state) {
			this.regions = project.addressDetail.state.regions;
		}
		this.formState = Constants.FORM_STATES.VIEW;
		this.setProject(project);
		this.initialStatus = project.status;
		this.initialQuoteDueDate = project.quoteDueDate;
		this.initialDecisionDate = project.decisionDate;
		this.discountTypeCurrent = this.project.discountType;
		if (this.project.discountType !== Constants.DISCOUNT_TYPES.onProject) {
			this.discountShowInExport = true;
		}
		this.populateEstimatorPicture();
		//TODO what is this for
		//Populate contacts from company contacts for consistency
		//between this.clients format and this.project.clients format
		this.populateClientsPicture();
		this.setupFormGroup();
	}

	private setUpCardConfigs() {
		this.siteAddressCardInputConfig = {
			hasDivider: true,
			titleText: this.text.siteAddress,
		};
		this.datesCardInputConfig = {
			titleText: this.text.keyDates,
		};
		this.clientCardInputConfig = {
			hasDivider: true,
			titleText: this.text.client,
		};
	}
}
