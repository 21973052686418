// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.comment-icon {
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.25rem;
}
.comment-icon .badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #76bc21;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
}
.comment-icon:hover {
  background-color: rgba(211, 211, 211, 0.4);
}
.comment-icon:hover svg {
  color: #414448;
}
.comment-icon svg {
  color: gray;
}

.comment-modal {
  margin-top: 0.5rem;
  overflow-y: auto;
  list-style-type: none;
  display: block;
  position: absolute;
  z-index: 1;
  background-color: white;
  border: 1px solid rgba(211, 211, 211, 0.75);
  border-radius: 0.5rem;
  padding: 1rem 1rem 0 1rem;
  width: 500px;
  height: 350px;
  right: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
