// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Top position alignment */
.popover-top {
	bottom: calc(100% + 10px);
	left: 50%;
	transform: translateX(-50%);
}

.align-start-top {
	left: 0;
	transform: translateY(0);
}

.align-middle-top {
	left: 50%;
	transform: translate(-50%, 0);
}

.align-end-top {
	left: 100%;
	transform: translateX(-100%);
}

/* Bottom position alignment */
.popover-bottom {
	top: calc(100% + 10px);
	left: 50%;
	transform: translateX(-50%);
}

.align-start-bottom {
	left: 0;
	transform: translateY(0);
}

.align-middle-bottom {
	left: 50%;
	transform: translate(-50%, 0);
}

.align-end-bottom {
	left: 100%;
	transform: translateX(-100%);
}

/* Left position alignment */
.popover-left {
	right: calc(100% + 10px);
	top: 50%;
	transform: translateY(-50%);
}

.align-start-left {
	top: 0;
	right: calc(100% + 10px);
	transform: translateY(0);
}

.align-middle-left {
	top: 50%;
	right: calc(100% + 10px);
	transform: translateY(-50%);
}

.align-end-left {
	top: 100%;
	right: calc(100% + 10px);
	transform: translateY(-100%);
}

/* Right position alignment */
.popover-right {
	left: calc(100% + 10px);
	top: 50%;
	transform: translateY(-50%);
}

.align-start-right {
	top: 0;
	left: calc(100% + 10px);
	transform: translateY(0);
}

.align-middle-right {
	top: 50%;
	left: calc(100% + 10px);
	transform: translateY(-50%);
}

.align-end-right {
	top: 100%;
	left: calc(100% + 10px);
	transform: translateY(-100%);
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
