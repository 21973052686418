// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.image-card {
  max-height: 170px;
  height: 170px;
}

.image-card-container {
  height: 165px;
}

.title-card-container {
  height: 90px;
}

.shadow-card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.6);
  transition: 0.3s;
}

.shadow-card:hover {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.6);
}

.star-icon {
  font-size: 22px;
  color: #f0ad4e;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
